























































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import moment from 'moment';

export default defineComponent({
  name: 'Dashboard',

  props: {
    students: {
      type: Array,
      required: true
    },
    sidebarExpanded: {
      type: Boolean,
      default: true
    }
  },

  setup(props, { emit }) {
    const gridSize = ref(Number(localStorage.getItem('dashboardGridSize')) || 10);
    const gridSizes = ref([6, 8, 10, 12, 15, 20]);
    const hoveredStudent = ref(null);
    const sortAscending = ref(localStorage.getItem('dashboardSortAscending') === 'true');
    const mousePosition = ref({ x: 0, y: 0 });
    const hoverCardStyle = ref({
      position: 'fixed',
      top: '0px',
      left: '0px',
      zIndex: 1000
    });

    const calculateHoverPosition = (event: MouseEvent, cardWidth = 300, cardHeight = 200) => {
      const viewport = {
        width: window.innerWidth,
        height: window.innerHeight
      };

      let x = event.clientX;
      let y = event.clientY;

      if (x + cardWidth + 20 > viewport.width) {
        x = x - cardWidth - 20;
      } else {
        x += 20;
      }

      if (y + cardHeight + 20 > viewport.height) {
        y = y - cardHeight - 20;
      } else {
        y += 20;
      }

      return { x, y };
    };

    const handleMouseMove = (event: MouseEvent, student: any) => {
      hoveredStudent.value = student;
      const { x, y } = calculateHoverPosition(event);
      hoverCardStyle.value = {
        position: 'fixed',
        top: `${y}px`,
        left: `${x}px`,
        zIndex: 1000
      };
    };

    // Color mixing helper function
    const mix = (color1: string, color2: string, weight: number) => {
      const d2h = d => d.toString(16).padStart(2, '0');
      const h2d = h => parseInt(h, 16);

      const c1 = color1.substring(1);
      const c2 = color2.substring(1);

      const r1 = h2d(c1.substring(0, 2));
      const g1 = h2d(c1.substring(2, 4));
      const b1 = h2d(c1.substring(4, 6));

      const r2 = h2d(c2.substring(0, 2));
      const g2 = h2d(c2.substring(2, 4));
      const b2 = h2d(c2.substring(4, 6));

      const r = Math.round(r1 + (r2 - r1) * weight);
      const g = Math.round(g1 + (g2 - g1) * weight);
      const b = Math.round(b1 + (b2 - b1) * weight);

      return `#${d2h(r)}${d2h(g)}${d2h(b)}`;
    };

    // Get lighter shade for text
    const getLighterShade = (hex: string, factor: number) => {
      const r = parseInt(hex.slice(1, 3), 16);
      const g = parseInt(hex.slice(3, 5), 16);
      const b = parseInt(hex.slice(5, 7), 16);

      return `rgb(${r + (255 - r) * factor}, ${g + (255 - g) * factor}, ${b + (255 - b) * factor})`;
    };

    // Update getProgressColor function to use 5% steps
    const getProgressColor = (completion: number) => {
      const colors = {
        green: '#6eba80', // Vuetify green
        yellow: '#fec34b', // Vuetify yellow
        orange: '#f79961', // Vuetify orange
        red: '#ea6764' // Vuetify red
      };

      // Round completion to nearest 5%
      const roundedCompletion = Math.round(completion / 5) * 5;

      if (roundedCompletion >= 75) {
        // 75-100%: yellow to green in 5% steps
        const step = Math.floor((roundedCompletion - 75) / 5);
        return mix(colors.yellow, colors.green, step / 5);
      }
      if (roundedCompletion >= 50) {
        // 50-75%: orange to yellow in 5% steps
        const step = Math.floor((roundedCompletion - 50) / 5);
        return mix(colors.orange, colors.yellow, step / 5);
      }
      if (roundedCompletion >= 25) {
        // 25-50%: red to orange in 5% steps
        const step = Math.floor((roundedCompletion - 25) / 5);
        return mix(colors.red, colors.orange, step / 5);
      }
      // 0-25%: pure red in 5% steps
      return colors.red;
    };

    // Update getStudentStyle to actually use getProgressColor
    const getStudentStyle = student => {
      const completion = student.adksCompletion || 0;
      const backgroundColor = getProgressColor(completion);
      // Adjust font size calculation
      const textColor = getLighterShade(backgroundColor, 0.7);
      const baseFontSize = 2.5; // Increased base size
      const scaleFactor = Math.max(6 / gridSize.value, 0.3);

      return {
        backgroundColor,
        color: textColor,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px',
        cursor: 'pointer',
        height: '100%',
        width: '100%',
        fontSize: `${baseFontSize * scaleFactor}rem`,
        lineHeight: '1.2'
      };
    };

    // Sort students by completion percentage
    const sortedStudents = computed(() => {
      const sorted = [...props.students].sort((a, b) => {
        const aCompletion = a.adksCompletion || 0;
        const bCompletion = b.adksCompletion || 0;
        return sortAscending.value ? aCompletion - bCompletion : bCompletion - aCompletion;
      });
      return sorted;
    });

    // Grid layout styling
    const gridStyle = computed(() => {
      const gap = 2;
      const totalGapWidth = (gridSize.value - 1) * gap;

      // Calculate available space accounting for both sidebar parts
      const navBarWidth = 64; // Width of always-visible navigation bar

      // Get viewport width and calculate 25vw in pixels
      const viewportWidth = window.innerWidth;
      const expandedWidth = Math.floor((viewportWidth * 25) / 100); // 25vw in pixels
      const expandedSidebarWidth = props.sidebarExpanded ? expandedWidth : 0; // Width of expandable sidebar

      const totalSidebarWidth = navBarWidth + expandedSidebarWidth;

      const containerWidth = `calc(100vw - ${totalSidebarWidth}px)`;
      const squareSize = `calc((${containerWidth} - ${totalGapWidth}px) / ${gridSize.value})`;

      return {
        display: 'grid',
        gridTemplateColumns: `repeat(${gridSize.value}, ${squareSize})`,
        gridAutoRows: squareSize,
        gap: `${gap}px`,
        justifyContent: 'center',
        alignContent: 'start',
        width: containerWidth,
        height: '100vh',
        overflowY: 'auto',
        overflowX: 'hidden',
        margin: 0,
        padding: 0,
        position: 'absolute',
        right: 0,
        top: 0,
        background: '#ffffff'
      };
    });

    // Get student initials
    const getInitials = (name: string) => {
      if (!name) return '';
      const words = name.split(' ');
      // For larger grid sizes (15 or 20), only show first two initials
      const maxInitials = gridSize.value >= 15 ? 2 : 3;
      return words
        .slice(0, maxInitials)
        .map(word => word[0])
        .join('')
        .toUpperCase();
    };

    // Change grid size
    const changeGridSize = (size: number) => {
      gridSize.value = size;
      localStorage.setItem('dashboardGridSize', size.toString());
    };

    // Change sort order
    const changeSortOrder = (ascending: boolean) => {
      sortAscending.value = ascending;
      localStorage.setItem('dashboardSortAscending', ascending.toString());
    };

    // Format date
    const formatDate = date => {
      return moment(date).fromNow();
    };

    // Select student
    const handleStudentClick = student => {
      emit('view-change', 'participant', student);
    };

    // Add a new method to format the completion percentage
    const formatCompletion = (completion: number) => {
      return `${Math.round(completion || 0)}%`;
    };

    const formatTotalTime = adks => {
      if (!adks || !Array.isArray(adks)) return 'No time recorded';

      const totalMinutes = adks.reduce((total, adk) => {
        return total + (typeof adk?.timeTaken === 'number' ? adk.timeTaken : 0);
      }, 0);

      if (totalMinutes === 0) return 'No time recorded';

      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;

      if (hours === 0) {
        return `${minutes}m`;
      }
      return `${hours}h ${minutes}m`;
    };

    return {
      gridSize,
      gridSizes,
      hoveredStudent,
      sortedStudents,
      gridStyle,
      getStudentStyle,
      getInitials,
      changeGridSize,
      formatDate,
      handleStudentClick,
      sortAscending,
      changeSortOrder,
      handleMouseMove,
      hoverCardStyle,
      formatCompletion,
      formatTotalTime
    };
  }
});
